import React from "react"
import * as cn from "classnames"
import Layout from "../components/layout"
import {Accordion, Card, Button} from 'react-bootstrap'

export default class Help extends React.Component {
    state = {
        activeTab: 0
    }

    setActiveTab(idx) {
        this.setState({activeTab: idx})
    }

    render() {
        return (
            <Layout className="solid">
                <div className="help">
                    <div className="container">
                        <div className="item">
                            <span className="icon-phone icon"></span>
                            <p>Zadzwoń do nas</p>
                        </div>
                        <div className="item">
                            <span className="icon-question icon"></span>
                            <p>Zgłoś problem</p>
                        </div>
                        <div className="item">
                            <span className="icon-engineer icon"></span>
                            <p>Umow się na naprawę</p>
                        </div>
                        <div className="item">
                            <span className="icon-phone icon"></span>
                            <p>Problem z głowy</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="page-title">
                        <h3>Lorem ipsum dolor sit amet, consetetur.</h3>
                    </div>
                    <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a cursus.
                        Sed id metus et libero mattis iaculis. Aliquam pretium viverra velit at accumsan. Ut velit
                        ligula, euismod a ultricies eu, placerat rutrum turpis. Aenean convallis orci vel libero
                        condimentum viverra. Maecenas egestas accumsan sapien. Cras laoreet neque a varius fringilla. In
                        quis magna laoreet, vestibulum sem vitae, eleifend diam. Aliquam sed metus iaculis, imperdiet
                        nisl non, elementum lectus. Quisque hendrerit luctus porttitor. In ut feugiat lorem.Quisque
                        congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a cursus. Sed id
                        metus et libero mattis iaculis. </p>
                    <div className="text-center">
                        <span className="btn btn--yellow">
                            <span className="icon-phone icon"></span>
                            +48 501 256 234
                        </span>
                        <span className="btn btn--yellow">
                            <span className="icon-email icon"></span>
                            serwis@falve.com
                        </span>
                    </div>
                    <div className="page-title">
                        <h3>Częste pytania</h3>
                    </div>
                    <Accordion defaultActiveKey="0">
                        <Card className={cn(this.state.activeTab === 0 ? "active" : "")}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => this.setActiveTab(0)}>
                                    Lorem ipsum
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>Hello! I'm the body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={cn(this.state.activeTab === 1 ? "active" : "")}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => this.setActiveTab(1)}>
                                    Lorem ipsum
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>Hello! I'm another body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={cn(this.state.activeTab === 2 ? "active" : "")}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={() => this.setActiveTab(2)}>
                                    Lorem ipsum
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>Hello! I'm another body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={cn(this.state.activeTab === 3 ? "active" : "")}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={() => this.setActiveTab(3)}>
                                    Lorem ipsum
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>Hello! I'm another body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </Layout>
        )
    }
}